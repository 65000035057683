import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DirectDebitDebitorList } from "../components/DirectDebitDebitorList";
import { DirectDebitList } from "../components/DirectDebitList";
import { ErrorView } from "../components/ErrorView";
import { t } from "../utils/i18n";
import { Router, accountDirectDebitRoutes } from "../utils/routes";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
    paddingTop: spacings[24],
  },
});

type Props = {
  accountId: string;
  accountMembershipId: string;
  canViewAccount: boolean;
};

export const DirectDebitPage = ({ accountId, accountMembershipId, canViewAccount }: Props) => {
  const route = Router.useRoute(accountDirectDebitRoutes);

  return (
    <ResponsiveContainer breakpoint={breakpoints.large} style={styles.root}>
      {({ small }) => (
        <>
          <TabView
            padding={small ? 24 : 40}
            sticky={true}
            tabs={[
              {
                label: t("paymentMethod.directDebit.receipts"),
                url: Router.AccountDirectDebitRoot({ accountMembershipId }),
              },
              {
                label: t("paymentMethod.directDebit.debitors"),
                url: Router.AccountDirectDebitDebitorList({ accountMembershipId }),
              },
            ]}
            otherLabel={t("common.tabs.other")}
          />

          <Space height={24} />

          {match(route)
            .with({ name: "AccountDirectDebitRoot" }, ({ params }) => (
              <DirectDebitList
                accountId={accountId}
                accountMembershipId={accountMembershipId}
                canViewAccount={canViewAccount}
                params={params}
              />
            ))
            .with({ name: "AccountDirectDebitDebitorList" }, ({ params }) => (
              <DirectDebitDebitorList
                accountId={accountId}
                accountMembershipId={accountMembershipId}
                canViewAccount={canViewAccount}
                params={params}
              />
            ))
            .otherwise(() => (
              <ErrorView />
            ))}
        </>
      )}
    </ResponsiveContainer>
  );
};
