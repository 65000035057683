import { Dict } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import {
  FilterCheckboxDef,
  FilterDateDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/lake/src/components/Filters";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Space } from "@swan-io/lake/src/components/Space";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { locale, t } from "../utils/i18n";
import {
  isAfterUpdatedAtSelectable,
  isBeforeUpdatedAtSelectable,
  validateAfterUpdatedAt,
  validateBeforeUpdatedAt,
} from "../utils/validations";

const isAfterUpdatedAtFilter: FilterDateDef = {
  type: "date",
  label: t("transactionList.filter.isAfterUpdatedAt"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: validateAfterUpdatedAt,
  isSelectable: isAfterUpdatedAtSelectable,
};

const isBeforeUpdatedAtFilter: FilterDateDef = {
  type: "date",
  label: t("transactionList.filter.isBeforeUpdatedAt"),
  cancelText: t("common.cancel"),
  submitText: t("common.filters.apply"),
  noValueText: t("common.none"),
  dateFormat: locale.dateFormat,
  validate: validateBeforeUpdatedAt,
  isSelectable: isBeforeUpdatedAtSelectable,
};

const debitorFilter: FilterCheckboxDef<string> = {
  type: "checkbox",
  checkAllLabel: t("common.filters.all"),
  items: [],
  label: t("paymentMethod.directDebit.debitor"),
  submitText: t("common.filters.apply"),
};

export const defaultFiltersDefinition = {
  isAfterUpdatedAt: isAfterUpdatedAtFilter,
  isBeforeUpdatedAt: isBeforeUpdatedAtFilter,
  debitor: debitorFilter,
};

export type DebitorFiltersState = FiltersState<typeof defaultFiltersDefinition>;

type DebitorListFilterProps = {
  filters: DebitorFiltersState;
  onChange: (values: Partial<DebitorFiltersState>) => void;
  onRefresh: () => void;
  available?: readonly (keyof DebitorFiltersState)[];
  children?: ReactNode;
  large?: boolean;
  availableDebitors?:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
  filtersDefinition?: {
    isAfterUpdatedAt: FilterDateDef;
    isBeforeUpdatedAt: FilterDateDef;
    debitor: FilterCheckboxDef<string>;
  };
};

const defaultAvailableFilters = ["isAfterUpdatedAt", "isBeforeUpdatedAt", "debitor"] as const;

export const DebitorListFilter = ({
  filters,
  children,
  onChange,
  onRefresh,
  availableDebitors,
  large = true,
  available = defaultAvailableFilters,
  filtersDefinition = defaultFiltersDefinition,
}: DebitorListFilterProps) => {
  if (availableDebitors !== undefined) {
    debitorFilter.items = availableDebitors;
  }
  const availableSet = useMemo(() => new Set(available), [available]);
  const availableFilters: { name: keyof typeof filters; label: string }[] = useMemo(
    () =>
      (
        [
          {
            name: "isAfterUpdatedAt",
            label: t("transactionList.filter.isAfterUpdatedAt"),
          },
          {
            name: "isBeforeUpdatedAt",
            label: t("transactionList.filter.isBeforeUpdatedAt"),
          },
          {
            name: "debitor",
            label: t("paymentMethod.directDebit.debitor"),
          },
        ] as const
      ).filter(item => availableSet.has(item.name)),
    [availableSet],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  return (
    <>
      <Box direction="row" alignItems="center">
        {children != null ? (
          <>
            {children}

            <Space width={16} />
          </>
        ) : null}

        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          title={t("common.chooseFilter")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
          large={large}
        />

        {large ? (
          <>
            <Space width={16} />

            <LakeButton
              ariaLabel={t("common.refresh")}
              mode="secondary"
              size="small"
              icon="arrow-counterclockwise-filled"
              onPress={onRefresh}
            />
          </>
        ) : null}

        <Fill minWidth={16} />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={value => onChange({ ...value })}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
