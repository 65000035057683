import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ListRightPanelContent } from "@swan-io/lake/src/components/ListRightPanel";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { ScrollView, StyleSheet } from "react-native";
import { formatCurrency, formatDateTime, t } from "../utils/i18n";
import { Debitor } from "../utils/types";
import { ErrorView } from "./ErrorView";

const styles = StyleSheet.create({
  container: {
    ...commonStyles.fill,
  },
  tile: {
    alignItems: "center",
  },
  debitAmount: {
    marginLeft: -16,
  },
  wrapText: {
    wordBreak: "break-all",
  },
});

type Props = {
  debitor: Debitor;
  large: boolean;
};

export const DirectDebitDebitorDetail = ({ debitor, large }: Props) => {
  if (debitor == null) {
    return <ErrorView />;
  }

  const lastReceiptDateTime = (
    <LakeLabel
      type="viewSmall"
      label={t("paymentMethod.directDebit.list.lastReceipt")}
      render={() => (
        <LakeText variant="regular" color={colors.gray[900]}>
          {formatDateTime(new Date(debitor.columns.lastReceipt), "LLL")}
        </LakeText>
      )}
    />
  );

  const numReceipts = (
    <LakeLabel
      type="viewSmall"
      label={t("paymentMethod.directDebit.list.numReceipts")}
      render={() => (
        <LakeText variant="regular" color={colors.gray[900]}>
          {debitor.columns.numReceipts}
        </LakeText>
      )}
    />
  );

  const truncateTransactionId = (id: string) => id.split("#", 2)[0];
  const truncatesTransactionId = truncateTransactionId(debitor.id);

  const transactionId = (
    <LakeLabel
      type="viewSmall"
      label={t("paymentMethod.directDebit.list.lastReceiptId")}
      actions={
        truncatesTransactionId != null ? (
          <LakeCopyButton
            valueToCopy={truncatesTransactionId}
            copiedText={t("copyButton.copiedTooltip")}
            copyText={t("copyButton.copyTooltip")}
          />
        ) : undefined
      }
      render={() => (
        <LakeText variant="regular" color={colors.gray[900]}>
          {truncatesTransactionId}
        </LakeText>
      )}
    />
  );

  return (
    <ScrollView contentContainerStyle={large ? commonStyles.fill : undefined}>
      <ListRightPanelContent large={large} style={styles.container}>
        <Tile style={styles.tile}>
          <Space height={12} />

          <LakeHeading
            variant="h1"
            level={2}
            align="center"
            style={debitor.columns.totalAmount.includes("-") ? styles.debitAmount : null}
          >
            {(debitor.columns.totalAmount.includes("-") ? "" : "+") +
              formatCurrency(Number(debitor.columns.totalAmount), debitor.columns.currency)}
          </LakeHeading>

          <Space height={8} />

          <LakeHeading
            variant="h4"
            level={3}
            align="center"
            color={colors.gray[700]}
            style={styles.wrapText}
          >
            {debitor.name}
          </LakeHeading>
        </Tile>

        <Space height={24} />

        <ScrollView style={commonStyles.fill} contentContainerStyle={commonStyles.fill}>
          <ReadOnlyFieldList>
            {lastReceiptDateTime}
            {numReceipts}
            {transactionId}
          </ReadOnlyFieldList>
        </ScrollView>
      </ListRightPanelContent>
    </ScrollView>
  );
};
