import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import dayjs from "dayjs";
import { StyleSheet, View } from "react-native";
import { formatCurrency } from "../utils/i18n";

const styles = StyleSheet.create({
  cell: {
    display: "flex",
    paddingHorizontal: spacings[16],
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    width: 1,
  },
  cellRightAlign: {
    justifyContent: "flex-end",
  },
  amounts: {
    alignItems: "flex-end",
  },
  overflowingText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export const DebitorAmountCell = ({ value, currency }: { value: string; currency: string }) => {
  return (
    <View style={[styles.cell, styles.cellRightAlign]}>
      <View style={styles.amounts}>
        <LakeHeading
          level={4}
          variant="h5"
          color={value.includes("-") ? colors.gray[900] : colors.positive[600]}
        >
          {(value.includes("-") ? "" : "+") + formatCurrency(Number(value), currency)}
        </LakeHeading>
      </View>
    </View>
  );
};

export const DebitorTextCell = ({ text }: { text: string }) => {
  return (
    <View style={styles.cell}>
      <LakeHeading variant="h5" level={3} style={styles.overflowingText}>
        {text}
      </LakeHeading>
    </View>
  );
};

export const DebitorDateCell = ({ date }: { date: string }) => {
  return (
    <View style={[styles.cell, styles.cellRightAlign]}>
      <LakeText align="right" variant="smallMedium" color={colors.gray[600]}>
        {dayjs(date).format("LL")}
      </LakeText>
    </View>
  );
};
