import { BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useMemo } from "react";
import { View } from "react-native";
import { match } from "ts-pattern";
import { DirectDebitPage } from "../pages/AccountDirectDebitPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { accountDirectDebitRoutes, Router } from "../utils/routes";

type Props = {
  accountId: string;
  accountMembershipId: string;
  canViewAccount: boolean;
};

export const DirectDebitArea = ({ accountId, accountMembershipId, canViewAccount }: Props) => {
  const route = Router.useRoute(accountDirectDebitRoutes);

  const rootLevelCrumbs = useMemo(() => {
    return [
      {
        label: t("paymentMethod.directDebit"),
        link: Router.AccountDirectDebitRoot({ accountMembershipId }),
      },
    ];
  }, [accountMembershipId]);

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <View role="main" style={commonStyles.fill}>
        {match(route)
          .with(
            { name: "AccountDirectDebitRoot" },
            { name: "AccountDirectDebitDebitorList" },
            () => (
              <DirectDebitPage
                accountId={accountId}
                accountMembershipId={accountMembershipId}
                canViewAccount={canViewAccount}
              />
            ),
          )
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
