import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { SimpleHeaderCell } from "@swan-io/lake/src/components/FixedListViewCells";
import { ColumnConfig, PlainListView } from "@swan-io/lake/src/components/PlainListView";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { tabsViewHeight } from "@swan-io/lake/src/components/TabView";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { capitalize } from "@swan-io/lake/src/utils/string";
import dayjs from "dayjs";
import { ReactElement, ReactNode } from "react";
import { t } from "../utils/i18n";
import { Debitor } from "../utils/types";
import { DebitorAmountCell, DebitorDateCell, DebitorTextCell } from "./DebitorListCells";

type ExtraInfo = undefined;

const columns: ColumnConfig<Debitor, ExtraInfo>[] = [
  {
    id: "label",
    width: "grow",
    title: t("paymentMethod.directDebit.debitor"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) => <DebitorTextCell text={item.name} />,
  },
  {
    id: "date",
    width: 200,
    title: t("paymentMethod.directDebit.list.lastReceipt"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-end" />,
    renderCell: ({ item }) => <DebitorDateCell date={item.columns.lastReceipt} />,
  },
  {
    id: "emited",
    width: 160,
    title: t("paymentMethod.directDebit.list.numReceipts"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-end" />,
    renderCell: ({ item }) => <DebitorTextCell text={item.columns.numReceipts.toString()} />,
  },
  {
    id: "amount",
    width: 160,
    title: t("paymentMethod.directDebit.list.totalAmount"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-end" />,
    renderCell: ({ item }) => (
      <DebitorAmountCell value={item.columns.totalAmount} currency={item.columns.currency} />
    ),
  },
];

const smallColumns: ColumnConfig<Debitor, ExtraInfo>[] = [
  {
    id: "label",
    width: "grow",
    title: t("paymentMethod.directDebit.debitor"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item }) => <DebitorTextCell text={item.name} />,
  },
  {
    id: "amount",
    width: 48,
    title: t("paymentMethod.directDebit.list.totalAmount"),
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} justifyContent="flex-end" />,
    renderCell: ({ item }) => (
      <DebitorAmountCell value={item.columns.totalAmount} currency={item.columns.currency} />
    ),
  },
];

type Props = {
  debitors: Debitor[];
  activeRowId?: string;
  getRowLink: (item: LinkConfig<Debitor, ExtraInfo>) => ReactElement;
  onActiveRowChange: (element: HTMLElement) => void;
  renderEmptyList: () => ReactNode;
  withGrouping: boolean;
};

export const DebitorList = ({
  debitors,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  renderEmptyList,
  withGrouping = true,
}: Props) => {
  const { desktop } = useResponsive();
  const headerHeight = 48;

  return (
    <ResponsiveContainer style={commonStyles.fill} breakpoint={breakpoints.large}>
      {({ large }) => (
        <>
          <PlainListView
            withoutScroll={!desktop}
            stickyOffset={desktop ? 0 : tabsViewHeight - 1}
            data={debitors}
            keyExtractor={item => item.name}
            groupBy={
              withGrouping
                ? item =>
                    large
                      ? capitalize(dayjs(item.columns.lastReceipt).format("MMMM YYYY"))
                      : dayjs(item.columns.lastReceipt).format("LL")
                : undefined
            }
            headerHeight={headerHeight}
            groupHeaderHeight={headerHeight}
            rowHeight={56}
            extraInfo={undefined}
            columns={columns}
            onActiveRowChange={onActiveRowChange}
            activeRowId={activeRowId}
            smallColumns={smallColumns}
            getRowLink={getRowLink}
            renderEmptyList={renderEmptyList}
          />
        </>
      )}
    </ResponsiveContainer>
  );
};
