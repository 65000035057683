import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import { FocusTrapRef } from "@swan-io/lake/src/components/FocusTrap";
import { ListRightPanel } from "@swan-io/lake/src/components/ListRightPanel";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints, spacings } from "@swan-io/lake/src/constants/design";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useCallback, useMemo, useRef, useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import { DirectDebitDebitorListPageDocument } from "../graphql/partner";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { Debitor } from "../utils/types";
import { DebitorList } from "./DebitorList";
import {
  DebitorFiltersState,
  DebitorListFilter,
  defaultFiltersDefinition,
} from "./DebitorListFilter";
import { DirectDebitDebitorDetail } from "./DirectDebitDebitorDetail";
import { ErrorView } from "./ErrorView";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
  },
  filters: {
    paddingHorizontal: spacings[24],
    paddingBottom: spacings[12],
  },
  filtersLarge: {
    paddingHorizontal: spacings[40],
  },
});

type Props = {
  accountId: string;
  accountMembershipId: string;
  canViewAccount: boolean;
  params: {
    isAfterUpdatedAt?: string | undefined;
    isBeforeUpdatedAt?: string | undefined;
    debitor?: string[] | undefined;
  };
};

export const DirectDebitDebitorList = ({
  accountId,
  accountMembershipId,
  canViewAccount,
  params,
}: Props) => {
  const filters: DebitorFiltersState = useMemo(() => {
    return {
      includeRejectedWithFallback: false,
      isAfterUpdatedAt: params.isAfterUpdatedAt,
      isBeforeUpdatedAt: params.isBeforeUpdatedAt,
      debitor: params.debitor,
    } as const;
  }, [params.isAfterUpdatedAt, params.isBeforeUpdatedAt, params.debitor]);

  const paymentProduct = useMemo(() => {
    return ["SEPADirectDebit" as const];
  }, []);

  const status = useMemo(() => {
    return ["Booked" as const];
  }, []);

  const { debitor, ...filterWithoutDebitor } = filters;
  const { data, reload } = useUrqlQuery(
    {
      query: DirectDebitDebitorListPageDocument,
      variables: {
        accountId,
        filters: {
          ...filterWithoutDebitor,
          paymentProduct,
          status,
        },
        canViewAccount,
      },
    },
    [],
  );

  const debitorsFlat = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(data => Option.fromNullable(data.account?.transactions))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  const uniqueDebitors = new Set<string>();
  const filteredDebitors = new Set<string>(debitor);
  const debitors: Debitor[] = [];
  debitorsFlat.forEach(row => {
    if (row.__typename === "SEPADirectDebitTransaction") {
      const name = row.creditor.name;
      uniqueDebitors.add(name);
      const found = debitors.find(item => item.name == name);
      if (filteredDebitors.size === 0 || filteredDebitors.has(name)) {
        const amount = row.amount.value;
        if (found) {
          const data = found.columns;
          data.numReceipts += 1;
          data.totalAmount = (
            row.side === "Debit"
              ? parseFloat(data.totalAmount) - parseFloat(amount)
              : parseFloat(data.totalAmount) + parseFloat(amount)
          ).toFixed(2);
        } else {
          debitors.push({
            id: row.id,
            name: row.creditor.name,
            columns: {
              lastReceipt: row.executionDate,
              numReceipts: 1,
              totalAmount: row.side === "Debit" ? "-" + amount : amount,
              currency: row.amount.currency,
            },
          });
        }
      }
    }
  });

  const [activeDebitorId, setActiveDebitorId] = useState<string | null>(null);

  const panelRef = useRef<FocusTrapRef | null>(null);

  const onActiveRowChange = useCallback(
    (element: HTMLElement) => panelRef.current?.setInitiallyFocusedElement(element),
    [],
  );

  return (
    <ResponsiveContainer style={styles.root} breakpoint={breakpoints.large}>
      {({ large }) => (
        <>
          <Box style={[styles.filters, large && styles.filtersLarge]}>
            <DebitorListFilter
              filters={filters}
              onChange={({ ...filters }) =>
                Router.push("AccountDirectDebitDebitorList", {
                  accountMembershipId,
                  ...filters,
                })
              }
              onRefresh={reload}
              large={large}
              available={["isAfterUpdatedAt", "isBeforeUpdatedAt", "debitor"]}
              availableDebitors={Array.from(uniqueDebitors).map(row => {
                return { value: row, label: row };
              })}
              filtersDefinition={{
                ...defaultFiltersDefinition,
              }}
            ></DebitorListFilter>
          </Box>

          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={20}
                rowVerticalSpacing={0}
                groupHeaderHeight={48}
                headerHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: _data => (
                  <DebitorList
                    debitors={debitors}
                    onActiveRowChange={onActiveRowChange}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("paymentMethod.directDebit.list.noResultsDebitor")}
                      />
                    )}
                    withGrouping={true}
                    getRowLink={({ item }) => (
                      <Pressable onPress={() => setActiveDebitorId(item.id)} />
                    )}
                    activeRowId={activeDebitorId ?? undefined}
                  />
                ),
              }),
          })}

          <ListRightPanel
            ref={panelRef}
            keyExtractor={item => item.id}
            activeId={activeDebitorId}
            onActiveIdChange={setActiveDebitorId}
            onClose={() => setActiveDebitorId(null)}
            items={debitors}
            render={(debitor, large) => (
              <DirectDebitDebitorDetail large={large} debitor={debitor} />
            )}
            closeLabel={t("common.closeButton")}
            previousLabel={t("common.previous")}
            nextLabel={t("common.next")}
          />
        </>
      )}
    </ResponsiveContainer>
  );
};
